<div class="floating-menu" #menu>
  <div class="menu-content">
    <a class="logo" [routerLink]="['']">
      <picture>
        <source srcset="./assets/logo-gora.webp" type="image/webp" />
        <img src="./assets/logo-gora.png"
          alt="SushiMore Sushi Swarzędz - Restauracja Japońska Sushi bar Swarzędz, Sushi w Swarzędzu na dowóz, Sushi w ETC Swarzędz" />
      </picture>
    </a>
    <nav class="subpages">
      <a [routerLink]="['/menu']">MENU</a><a [routerLink]="['/contact']">KONTAKT</a>
      <a class="order-online" href="https://sushimore.goorder.pl">ZAMÓW ONLINE</a>
<!--      <img class="bag-icon bag" src="./assets/koszyk.svg" (click)="showCart()" alt="SUSHIMORE - koszyk" />-->
    </nav>
  </div>
</div>
