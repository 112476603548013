import {
  Component,
  OnInit,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { ShoppingCartService } from '../shopping-cart/shopping-cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss'],
})
export class FloatingMenuComponent implements OnInit {
  private menuContent: Element;
  isCartOpen: any;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private appFloatingMenu: ElementRef
  ) {}

  @ViewChild('menu', { static: true }) menu: ElementRef;
  @HostListener('window:scroll', ['$event'])
  onScroll($event: any): void {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      this.menu.nativeElement.style.background = 'white';
    } else {
      this.menu.nativeElement.style.background = 'rgba(255, 255, 255, 0.5)';
    }
  }

  ngOnInit() {
    this.menuContent =
      this.appFloatingMenu.nativeElement.querySelector('.menu-content');

    this.shoppingCartService.$isCartOpen.subscribe((isOpen) => {
      this.isCartOpen = isOpen;
      if (isOpen) {
        this.menuContent.classList.add('menu-with-cart');
        this.menuContent.querySelector('a').classList.add('without-padding');
        this.menuContent
          .querySelector('.subpages')
          .classList.add('without-padding');
      } else {
        this.menuContent.classList.remove('menu-with-cart');
        this.menuContent.querySelector('a').classList.remove('without-padding');
        this.menuContent
          .querySelector('.subpages')
          .classList.remove('without-padding');
      }
    });
  }

  showCart() {
    if (this.isCartOpen) {
      this.shoppingCartService.closeCart();
    } else {
      this.shoppingCartService.showCart();
    }
  }
}
