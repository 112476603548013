<div class="grid grid-nogutter shopping-cart">
  <div class="col-12 shopping-cart-label">
    <!-- <label>Koszyk</label> -->
    <span id="button-close-cart" (click)="closeCart()">KONTYNUUJ ZAKUPY</span>
  </div>
  <div class="col-12 col-nopad shopping-cart-body">
    <div *ngIf="cartItems.length !== 0">
      <ul class="cart-items-list">
        <li *ngFor="let cartItem of cartItems" class="col-12 cart-item">
          <div class="col-12 col-nopad">
            <div class="col-3 lg:col-4 col-nopad">
              <img [src]="cartItem.imgSrc" />
            </div>
            <div class="col-8 lg:col-7 col-nopad col-offset-1">
              <div class="cart-item-remove-container">
                <span class="cart-item-remove" (click)="remove(cartItem)">
                  <i class="cross-first-line"></i>
                  <i class="cross-second-line"></i>
                </span>
              </div>
              <div class="col-12 col-nopad cart-item-numbers">
                <p>x {{ cartItem.multiplier }}</p>
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fas fa-caret-up" (click)="increment(cartItem)"></i>
                  <i
                    class="fas fa-caret-down"
                    (click)="decrement(cartItem)"
                  ></i>
                </span>
                <p>{{ cartItem.sum | number: "1.2-2" }} zł</p>
              </div>
            </div>
          </div>

          <div class="col-12 col-nopad">
            <label
              >{{ cartItem.name }}
              {{ cartItem.amount ? " - " + cartItem.amount : "" }}</label
            >
          </div>
        </li>
      </ul>

      <div class="col-12 cart-summary">
        <app-cart-summary></app-cart-summary>

        <a (click)="closeCartPanel()" [routerLink]="'/order-online'"
          >Zamawiam</a
        >
      </div>
    </div>

    <div *ngIf="cartItems.length === 0" class="empty-cart">
      <label>Twój koszyk jest pusty</label>
      <a (click)="closeCartPanel()" [routerLink]="'/menu'"
        >Wybierz coś z menu</a
      >
    </div>
  </div>
</div>
