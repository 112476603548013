import { RouterModule } from '@angular/router';
import { ShoppingCartComponent } from './shopping-cart.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartSummaryModule } from '../_shared/cart-summary/cart-summary.module';

@NgModule({
  declarations: [ShoppingCartComponent],
  exports: [ShoppingCartComponent],
  imports: [CommonModule, RouterModule, CartSummaryModule],
})
export class ShoppingCartModule {}
