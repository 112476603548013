import { environment } from './../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SeoService } from './_shared/seo/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app';

  constructor(
    private router: Router,
    private seoService: SeoService,
    private activatedRoute: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      this.gtmService.addGtmToDom();
    }
    this.router.events
      .pipe(
        filter((val): val is NavigationEnd => val instanceof NavigationEnd),
        map(() => this.rootRoute(this.activatedRoute)),
        filter((route: ActivatedRoute) => {
          return route.outlet === 'primary';
        }),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: { [name: string]: any }) => {
        this.seoService.updateSeo(event.seo);
      });
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
