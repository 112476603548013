import { environment } from './../environments/environment';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { SharedService } from '../app/_shared/shared.service';

import { AppComponent } from './app.component';
import { ShoppingCartService } from './shopping-cart/shopping-cart.service';
import { OrderOnlineService } from './order-online/order-online.service';
import { ContactService } from './contact/contact.service';

import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserStateInterceptor } from './interceptors/browser-state.interceptor';
import { FloatingMenuModule } from './floating-menu/floating-menu.module';
import { FooterModule } from './footer/footer.module';
import { ShoppingCartModule } from './shopping-cart/shopping-cart.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    FloatingMenuModule,
    FooterModule,
    ShoppingCartModule,
  ],
  providers: [
    ShoppingCartService,
    SharedService,
    OrderOnlineService,
    ContactService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
