import { SettingApi, SettingsSM } from '../_interfaces/settings';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { merge, of, Observable, Subject, BehaviorSubject } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
@Injectable()
export class SharedService {
  private messageUrl = environment.apiUrl + '/message';
  private settingsUrl = environment.apiUrl + '/settings';
  selectedMenuId: string;

  imageLoadOffset$;
  settingsSubject = new BehaviorSubject<SettingsSM>({
    is_delivery_available: false,
  });
  $settings: Observable<SettingsSM>;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.selectedMenuId = '';
    this.imageLoadOffset$ = merge(of(300), of(100000).pipe(delay(1000)));
    this.http
      .get(this.settingsUrl)
      .pipe(
        map((res: SettingApi[]) => {
          return res.reduce((prev, curr) => {
            prev[curr.setting_name] = curr.setting_value;
            return prev;
          }, {} as SettingsSM);
        })
      )
      .subscribe((settings: SettingsSM) => {
        this.settingsSubject.next(settings);
      });
    this.$settings = this.settingsSubject.asObservable();
  }

  scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) window.scrollTo(0, 0);
  }

  getMessage(): Observable<any> {
    return this.http.get(this.messageUrl);
  }
}
