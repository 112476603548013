import { OpeningHoursLinked } from './../../_interfaces/opening-hours-linked';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { OpeningHours } from './../../_interfaces/opening-hours';

@Injectable({
  providedIn: 'root',
})
export class OpeningHoursService {
  private openingHoursUrl = environment.apiUrl + '/opening_hours';

  openingHoursSubject = new Subject<OpeningHours[]>();
  $openingHours: Observable<OpeningHours[]>;

  openingHoursLinkedSubject = new BehaviorSubject<OpeningHoursLinked[]>([]);
  $openingHoursLinked: Observable<OpeningHoursLinked[]>;

  openingInfoSubject = new BehaviorSubject<string>('');
  $openingInfo: Observable<string>;

  isOpenSubject = new BehaviorSubject<boolean>(false);
  $isOpen: Observable<boolean>;

  constructor(private http: HttpClient) {
    this.$openingHours = this.openingHoursSubject.asObservable();
    this.$openingHoursLinked = this.openingHoursLinkedSubject.asObservable();
    this.$openingInfo = this.openingInfoSubject.asObservable();
    this.$isOpen = this.isOpenSubject.asObservable();
    this.$openingHours.subscribe((openingHours) => {
      this.openingHoursLinkedSubject.next(this.mapOpeningHours(openingHours));
      this.openingInfoSubject.next(this.mapOpeningInfo(openingHours));
      this.isOpenSubject.next(this.isOpen(openingHours));
    });
    this.http.get(this.openingHoursUrl).subscribe((openingHours: any[]) => {
      let sortedOpeningHours = openingHours.sort((a, b) =>
        a.order > b.order ? 1 : -1
      );
      this.openingHoursSubject.next(sortedOpeningHours);
    });
  }

  private isOpen(openingHours: OpeningHours[]): boolean {
    const today = new Date(Date.now()).getDay();
    const todayOpeningHours =
      today > 0 ? openingHours[today - 1] : openingHours[6];
    return (
      todayOpeningHours.isOpen &&
      new Date(Date.now()).getHours() >= todayOpeningHours.opening &&
      new Date(Date.now()).getHours() < todayOpeningHours.closing
    );
  }

  private mapOpeningInfo(openingHours: OpeningHours[]): string {
    const today = new Date(Date.now()).getDay();
    const todayOpeningHours =
      today > 0 ? openingHours[today - 1] : openingHours[6];
    const actualHour = new Date(Date.now()).getHours();
    if (todayOpeningHours.opening > actualHour) {
      return (
        'Dziś restauracja jest otwarta i dowozi zamówienia od godz. ' +
        todayOpeningHours.opening +
        ':00 do ' +
        todayOpeningHours.closing +
        ':00'
      );
    } else if (todayOpeningHours.closing <= actualHour) {
      const tommorrow = (today + 1) % 7;
      return (
        'Dziś restauracja jest już zamknięta. Zapraszamy jutro. Otwieramy o godz. ' +
        openingHours[tommorrow].opening +
        ':00 i pracujemy do ' +
        openingHours[tommorrow].closing +
        ':00'
      );
    } else {
      return '';
    }
  }

  private mapOpeningHours(openingHours: any[]): OpeningHoursLinked[] {
    return openingHours.reduce((prev, curr) => {
      if (
        prev.length === 0 ||
        prev[prev.length - 1].opening !== curr.opening ||
        prev[prev.length - 1].closing !== curr.closing ||
        prev[prev.length - 1].isOpen !== curr.isOpen
      ) {
        prev.push({
          firstDay: curr.day,
          lastDay: curr.day,
          opening: curr.opening,
          closing: curr.closing,
          isOpen: curr.isOpen,
        });
      } else {
        prev[prev.length - 1].lastDay = curr.day;
      }
      return prev;
    }, [] as OpeningHoursLinked[]);
  }
}
