import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContactService {
  private contactUrl = environment.apiUrl + '/contact';

  constructor(private http: HttpClient) {}

  sendMessage(form): Observable<any> {
    return this.http.post(this.contactUrl, form);
  }
}
