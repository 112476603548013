import { RouterModule } from '@angular/router';
import { FloatingMenuComponent } from './floating-menu.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [FloatingMenuComponent],
  exports: [FloatingMenuComponent],
  imports: [CommonModule, RouterModule],
})
export class FloatingMenuModule {}
