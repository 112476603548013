<div class="summary">
  <p>
    WARTOŚĆ KOSZYKA: {{ (cartSum | async).orderValue | number: "1.2-2" }} zł
  </p>
  <p *ngIf="(settings | async).is_delivery_available">
    DOSTAWA: {{ (cartSum | async).shipmentValue }} zł
  </p>
  <p>ODBIÓR OSOBISTY: 0 zł</p>
  <br />
  <label *ngIf="(settings | async).is_delivery_available"
    >RAZEM Z DOSTAWĄ:
    {{ (cartSum | async).sumValue | number: "1.2-2" }} zł</label
  >
  <label
    >RAZEM ODBIÓR OSOBISTY:
    {{ (cartSum | async).orderValue | number: "1.2-2" }} zł</label
  >
  <caption>
    Do zamówienia zostanie doliczony koszt opakowań BIO (1 zł za opakowanie)
    oraz torby (2 zł za jedną torbę BIO)
  </caption>
</div>
<p class="choose-shipment">
  O sposobie dostawy lub odbioru zamówienia poinformuj nas podczas rozmowy
  telefonicznej
</p>
