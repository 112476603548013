import { CartItem } from './../../models/cart-item.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShoppingCartService } from '../shopping-cart/shopping-cart.service';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class OrderOnlineService {
  private ordersUrl = environment.apiUrl + '/orders';

  constructor(private http: HttpClient) {}

  sendOrder(phone: number, cartItems: CartItem[]): Observable<any> {
    const order = {
      order: cartItems.map((item) => {
        return {
          name: item.name,
          price: item.price,
          amount: item.amount,
          multiplier: item.multiplier,
        };
      }),
      phone,
    };

    const authHeaders = new HttpHeaders();
    authHeaders.append('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(this.ordersUrl, order, { headers: authHeaders });
  }
}
