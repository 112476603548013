import { CartSummary } from './../_interfaces/cart-summary';
import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { CartItem } from '../../models/cart-item.model';
import { Meal } from '../../models/meal.model';

@Injectable()
export class ShoppingCartService {
  private readonly shipmentCost = 25;

  constructor() {
    this.$isCartOpen = this.isCartOpenSubject.asObservable();
    this.$cartItems = this.cartItemsSubject.asObservable();
    this.$cartSum = this.cartSumSubject.asObservable();

    this.$cartItems.subscribe((cartItems) => {
      this.updateSum(cartItems);
    });
  }

  // Cart visibility
  isCartOpenSubject = new BehaviorSubject<boolean>(false);
  $isCartOpen: Observable<boolean>;

  // Cart items
  cartItemsSubject = new BehaviorSubject<CartItem[]>([]);
  $cartItems: Observable<CartItem[]>;

  // Cart sum
  cartSumSubject = new BehaviorSubject<CartSummary>({
    sumValue: 0,
    orderValue: 0,
    shipmentValue: this.shipmentCost,
  });
  $cartSum: Observable<CartSummary>;

  showCart(): void {
    this.isCartOpenSubject.next(true);
  }

  closeCart(): void {
    this.isCartOpenSubject.next(false);
  }

  addToCart(meal: Meal, cartItems: CartItem[]): void {
    const carItemIndex: number = this.findCartItemIndex(
      cartItems,
      meal.name,
      meal.amount
    );
    if (carItemIndex !== -1) {
      if (cartItems[carItemIndex].multiplier < meal.maxLimit) {
        cartItems[carItemIndex].multiplier += 1;
        cartItems[carItemIndex].sum += meal.price;
      }
    } else if (meal.maxLimit !== 0) {
      const newCartItem: CartItem = {
        name: meal.name,
        imgSrc: meal.imgSrc,
        amount: meal.amount,
        multiplier: 1,
        price: meal.price,
        sum: meal.price,
        discounted: meal.price === meal.promo_price,
        maxLimit: meal.maxLimit,
      };

      cartItems.push(newCartItem);
    }
    this.cartItemsSubject.next(cartItems);
  }

  removeFromCart(cartItem: CartItem, cartItems: CartItem[]): void {
    const carItemIndex: number = this.findCartItemIndex(
      cartItems,
      cartItem.name,
      cartItem.amount
    );

    cartItems.splice(carItemIndex, 1);
    this.cartItemsSubject.next(cartItems);
  }

  increaseMultiplier(cartItem: CartItem, cartItems: CartItem[]): void {
    const carItemIndex: number = this.findCartItemIndex(
      cartItems,
      cartItem.name,
      cartItem.amount
    );
    if (cartItems[carItemIndex].multiplier < cartItem.maxLimit) {
      cartItems[carItemIndex].multiplier += 1;
      cartItems[carItemIndex].sum += cartItems[carItemIndex].price;
    }
    this.cartItemsSubject.next(cartItems);
  }

  decreaseMultiplier(cartItem: CartItem, cartItems: CartItem[]): void {
    const carItemIndex: number = this.findCartItemIndex(
      cartItems,
      cartItem.name,
      cartItem.amount
    );

    if (cartItems[carItemIndex].multiplier > 1) {
      cartItems[carItemIndex].multiplier -= 1;
      cartItems[carItemIndex].sum -= cartItems[carItemIndex].price;
    }
    this.cartItemsSubject.next(cartItems);
  }

  private updateSum(cartItems: CartItem[]): void {
    let cartSum = {
      sumValue: 0,
      orderValue: 0,
      shipmentValue: this.shipmentCost,
    } as CartSummary;

    if (cartItems.length !== 0) {
      cartSum.orderValue = cartItems
        .map((cartItem) => {
          return cartItem.sum;
        })
        .reduce((prev, current) => {
          return prev + current;
        });

      cartSum.sumValue = cartSum.orderValue + cartSum.shipmentValue;
    }

    this.cartSumSubject.next(cartSum);
  }

  private findCartItemIndex(
    cartItems: CartItem[],
    cartItemName: string,
    cartItemAmount: string
  ): number {
    return cartItems.findIndex((cartItem: CartItem) => {
      return (
        cartItem.name === cartItemName && cartItem.amount === cartItemAmount
      );
    });
  }

  public clearCart(): void {
    this.cartItemsSubject.next([]);
  }
}
