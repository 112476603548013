<div class="all">
  <!-- gorne menu (floating-menu) -->
  <app-floating-menu></app-floating-menu>
  <!-- podstrony - komponenty ladowane przez routing : startowa (home), menu, promocje (sale),
            imprezy (events) kontakt (contact), zamów online (order-online),  koszyk (floating-menu) -->
  <router-outlet></router-outlet>
  <!-- stopka która jest na wszystkich stronach -->
  <app-footer></app-footer>
  <!-- koszyk zamowien (shopping-cart) -->
  <app-shopping-cart></app-shopping-cart>
</div>
