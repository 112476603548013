import { SettingsSM } from './../../_interfaces/settings';
import { SharedService } from './../shared.service';
import { CartSummary } from './../../_interfaces/cart-summary';
import { ShoppingCartService } from './../../shopping-cart/shopping-cart.service';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartSummaryComponent implements OnInit {
  cartSum = this.shoppingCartService.$cartSum;
  settings = this.sharedService.$settings;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {}
}
