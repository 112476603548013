import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SharedService } from '../_shared/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [],
})
export class FooterComponent implements OnInit {
  checkDisplay = '';

  constructor(public sharedService: SharedService, router: Router) {
    router.events.subscribe((res) => {
      if (router.url === '/contact' || router.url === '/order-online') {
        this.checkDisplay = 'none';
      } else {
        this.checkDisplay = '';
      }
    });
  }
  ngOnInit(): void {}
}
