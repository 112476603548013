import { CartSummary } from './../_interfaces/cart-summary';
import { OpeningHoursService } from './../_services/opening-hours/opening-hours.service';
import { Observable } from 'rxjs';
import { Component, OnInit, ElementRef } from '@angular/core';
import { ShoppingCartService } from './shopping-cart.service';
import { CartItem } from '../../models/cart-item.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit {
  shoppingCart: Element;
  cartItems: Array<CartItem> = [];
  cartSum: CartSummary;
  openingInfo = this.openingHoursService.$openingInfo;
  isShipmentActive: Observable<boolean> = this.openingHoursService.$isOpen;
  cartSumWithoutDelivery: CartSummary;
  cartWithoutDiscount = true;
  constructor(
    private shoppingCartService: ShoppingCartService,
    private openingHoursService: OpeningHoursService,
    private appShoppingCart: ElementRef
  ) {}

  ngOnInit() {
    this.shoppingCart =
      this.appShoppingCart.nativeElement.querySelector('.shopping-cart');

    this.shoppingCartService.$isCartOpen.subscribe((cartOpened) => {
      cartOpened
        ? this.shoppingCart.classList.add('cart-opened')
        : this.shoppingCart.classList.remove('cart-opened');
    });

    this.shoppingCartService.$cartItems.subscribe((cartItems) => {
      this.cartItems = cartItems;
      this.cartWithoutDiscount = !cartItems.find(
        (cartItem) => cartItem.discounted
      );
    });

    this.shoppingCartService.$cartSum.subscribe((cartSum: any) => {
      this.cartSum = cartSum;
      this.cartSumWithoutDelivery = {
        sumValue: cartSum.orderValue * 0.95,
        orderValue: cartSum.orderValue,
        shipmentValue: 0,
      };
    });
  }

  increment(cartItem: CartItem) {
    this.shoppingCartService.increaseMultiplier(cartItem, this.cartItems);
  }

  decrement(cartItem: CartItem) {
    this.shoppingCartService.decreaseMultiplier(cartItem, this.cartItems);
  }

  remove(cartItem: CartItem) {
    this.shoppingCartService.removeFromCart(cartItem, this.cartItems);
  }

  closeCart() {
    this.shoppingCartService.closeCart();
  }

  closeCartPanel() {
    this.shoppingCartService.closeCart();
  }
}
