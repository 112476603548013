import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then((m) => m.MenuModule),
  },

  {
    path: 'contact',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactModule),
  },
  // {
  //   path: 'order-online',
  //   loadChildren: () =>
  //     import('./order-online/order-online.module').then(
  //       (m) => m.OrderOnlineModule
  //     ),
  // },
  // {
  //   path: 'order-type',
  //   loadChildren: () =>
  //     import('./order-type/order-type.module').then((m) => m.OrderTypeModule),
  // },

  // {
  //   path: 'karta-rabatowa',
  //   loadChildren: () =>
  //     import('./discount-card/discount-card.module').then(
  //       (m) => m.DiscountCardModule
  //     ),
  // },
  {
    path: '404',
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top',
      scrollOffset: [0, 100],
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
